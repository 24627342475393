.margin-1{
  margin: 0;
}

.welcome{
  padding: 6em 2em;
  //color: #323232;
}

.login-b{
  background: transparent;
  color: #6da;
  border: 2px solid;
  border-radius: 15px;
  padding: 5px 40px;
  height: auto;
  margin: 40px 0;
}

.img img{
  max-width: 600px;
  width: 100%;
}

a{
  color: #EE6E73;
}

section{
  max-width: 700px;
}

h3{
  background: #EE6E72;
  color: white;
  display: inline-block;
  padding: 3px 10px;
}

.checked{
  color: #6da;
  padding: 0 10px;
}

@keyframes heartbeat {
    0% {
        transform: scale( .75);
    }
    20% {
        transform: scale( 1);
    }
    40% {
        transform: scale( .75);
    }
    60% {
        transform: scale( 1);
    }
    80% {
        transform: scale( .75);
    }
    100% {
        transform: scale( .75);
    }
}

.me-heart {
  animation: heartbeat 1s infinite;
}

.container-present{
  height: 100%;
  font-size: 20px;

  .full-heigh{
    height: 100%;
  }

  .grid-c-present{
    height: 100%;
    text-align: center;

    i{
      font-size: 3em;
      cursor: pointer;

      &.big{
        font-size: 10em;

        @media only screen and (max-width: 960px) {
          font-size: 8em;
        }
      }
    }
  }
}


@media only screen and (max-width: 1200px) {
  .container{
    background: white;
    padding: 10px;
  }
}
