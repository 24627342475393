@import '../../../assets/style/variable.scss';

.sch-popup-btn{
  color: $blue;
  cursor: pointer;
  font-weight: 600;
  padding-right: 10px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  color: $secondary;
  border-color: $secondary;
  box-shadow: -1px 0 0 0 $secondary;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $secondary;
  border-color: $secondary;
  box-shadow: -1px 0 0 0 $secondary;

  &:hover{
    color: $secondary;
    border-color: $secondary;
    box-shadow: -1px 0 0 0 $secondary;
  }
}

.ant-radio-button-wrapper:hover {
    position: relative;
    color: $secondary;
}

.slot-text{
  color: $secondary;
}

thead{
  .header2-text{
    display: none;

    &.date-nav{
      display: block;
    }
  }
}

.event-item{
  padding-right: 10px !important;
}

.date-nav{
  .date-nav-picker{
    input{
      min-width: 100px;
    }
  }
}
