// Override default variables before the import
//$body-bg: #ccc;
@import-normalize;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import './variable.scss';

a {
  color: #EE6E72;
  &:hover {
      color: #EE6E72;
      text-decoration: none;
  }
}

button{
  &:focus {
    outline: 0;
  }

  &.light{
    color: $light;
    border: 1px solid rgba(228,	229,	232, 0.5);
  }

  cursor: pointer;

}

ul{
  list-style-type: none;
}

.cursor-pointer{
  cursor: pointer;
}

.disabled-link {
  pointer-events: none;
}

.white-button{
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
}

.zoom-big-leave{
  display: none !important;
}

.text-success{
  color: $primary !important;
}

.text-success-light{
  color: $primary-light;
}

.text-error{
  color: $error-color;
}

.text-blue{
  color: $blue;
}

.text-white{
  color: $white;
}

.text-primary-light{
  color: $primary-light;
}

.text-bold{
  font-weight: 600 !important;
}

.text-small{
  font-size: 0.75rem !important;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.text-helper{
  margin: 0;
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
}

.select-margin{
  margin-top: 12px;
  margin-bottom: 8px;
}

.margin-auto{
  margin: auto !important;
}

.margin-0{
  margin: 0;
}

.pi-width{
  width: 12em;
  margin-bottom: 0;
}

.date-nav{
  .material-icons{
    vertical-align: middle;

    &:hover{
      cursor: pointer;
      color: $secondary;
    }
  }

  input{
    width: 6ch;
  }

  .MuiFormControl-marginNormal{
    margin: 4px 0;
    vertical-align: middle;
  }

  .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before{
    border: 0;
  }

  button{
    color: $secondary;
  }

  span{
    font-weight: 300;
  }

}

.MuiAutocomplete-root{
  width: 100% !important;
}

.bg{
  background: url('/bg5.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: -10;
  min-height: 100vh;
}

.bg-logo{
  background: url('../img/brand/med-icon-half.png') no-repeat right bottom fixed;
  -webkit-background-size: 256px;
  -moz-background-size: 256px;
  -o-background-size: 256px;
  background-size: 256px;
  z-index: -9;
}

.pow-checkbox{
  margin: auto !important;
  margin-bottom: 0 !important;
  padding: 0 0 0 10px !important;
  height: 100% !important;
  width: 100% !important;
}
