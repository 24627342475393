$primary: #42D4C3;
$primary-light: #69FFED;
$primary-dark: #1C877B;
$secondary: #EE6E72;
$secondary-ligt: #FF9869;
$secondary-dark: #873E1C;
$tertiary: #90CDF9;
$tertiary-light: #ADDDFF;
$tertiary-dark: #5388AD;
$blue: #90CDF9;
$light: #E4E5E8;
$white: #fff;
$error-color: #EE6E72;
